import React, { useState } from "react";
import KeyEingabe from "./KeyEingabe";
import Preview from "./Preview";

const Main = () => {
  const [key, setKey] = useState(null);

  if (!key) {
    return <KeyEingabe setKey={setKey} />;
  }

  return <Preview projectKey={key} logout={() => setKey(null)} />;
};

export default Main;
