import React, { useState, useEffect } from "react";
import API from "../utils/API";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Box,
  Paper
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { grey } from "@material-ui/core/colors";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from "react-scroll";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    background: grey[100],
    height: "100vh"
  },
  tb: {
    display: "flex",
    justifyContent: "space-between"
  },
  appBar: {
    background: grey[800]
  },
  img: {
    width: "100%",
    height: "auto"
  },
  imgWrapper: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0
  },
  container: {
    height: "calc(100vh - 170px)",
    width: "calc(100vw - 180px)",
    position: "absolute",
    left: 90,
    bottom: 90,
    overflow: "auto",
    cursor: "pointer",
    transition: "all 0.3s"
  },
  containerFullscreen: {
    height: "100vh",
    width: "100vw",
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: "0 !important"
  },
  btnIcon: {
    marginRight: 8
  },
  actions: {
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0
  },
  btnFullScreen: {
    background: grey[800],
    color: "#fff",
    "&:hover": {
      background: grey[900]
    }
  }
}));

const Preview = ({ projectKey, logout }) => {
  const [fullscreen, setFullscreen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [title, setTitle] = useState("");
  const [screens, setScreens] = useState([]);
  const classes = useStyles();
  useEffect(() => {
    if (!projectKey) {
      return;
    }
    async function fetchData() {
      try {
        const body = {
          filter: { key: projectKey }
        };
        const resultData = await API.post(
          "get/project?token=c3ddf499efbac92b189b69f1b82889",
          body
        );
        const data = resultData.data ? resultData.data : null;
        if (data.entries && data.entries.length) {
          const entry = data.entries[0];
          setTitle(entry.title);
          setScreens(entry.Screens);
        }
        console.log(data);
      } catch (e) {
        console.log("FEHLER: ", e);
      }
    }
    fetchData();
  }, [projectKey]);

  const handleNext = () => {
    setCurrent(cur => (cur < screens.length - 1 ? cur + 1 : 0));
    scrollToTop();
  };

  const handlePrev = () => {
    setCurrent(cur => (cur > 0 ? cur - 1 : screens.length - 1));
    scrollToTop();
  };

  const scrollToTop = () => {
    scroll.scrollToTop({
      containerId: "foo",
      duration: 500
    });
  };

  const toggleFullscreen = () => {
    setFullscreen(full => !full);
  };

  if (!projectKey) {
    return null;
  }

  console.log(screens[current]);

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.tb}>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton color="inherit" onClick={handlePrev}>
              <ChevronLeft />
            </IconButton>
            <Typography variant="body2">
              Screen {current + 1} von {screens.length}
            </Typography>
            <IconButton color="inherit" onClick={handleNext}>
              <ChevronRight />
            </IconButton>
          </Box>
          <Button color="inherit" onClick={() => logout()}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      {Boolean(screens[current] && screens[current].path) && (
        <>
          <Paper
            className={clsx(classes.container, {
              [classes.containerFullscreen]: fullscreen
            })}
            id="foo"
            onClick={handleNext}
          >
            <Element name="firstInsideContainer">
              <img
                className={classes.img}
                src={
                  "https://layout-cockpit.wamedia.io" + screens[current].path
                }
              />
            </Element>
          </Paper>
        </>
      )}
      <Box
        p={3}
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.actions}
      >
        <Button
          variant="contained"
          color="default"
          onClick={toggleFullscreen}
          className={classes.btnFullScreen}
        >
          {!fullscreen && (
            <>
              <FullscreenIcon className={classes.btnIcon} />
              Vollbild
            </>
          )}
          {fullscreen && (
            <>
              <FullscreenExitIcon className={classes.btnIcon} />
              Vollbild verlassen
            </>
          )}
        </Button>
      </Box>
    </div>
  );
};

export default Preview;
