import React from "react";
import {
  Container,
  Box,
  Paper,
  Typography,
  Fade,
  IconButton,
  Grid,
  TextField,
  Button
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { grey } from "@material-ui/core/colors";
import Logo from "../images/logo_wamedia.png";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    background: grey[100]
  },
  logo: {
    width: 120,
    height: "auto"
  }
}));

const KeyEingabe = ({ setKey }) => {
  const [values, setValues] = React.useState({
    key: ""
  });

  const classes = useStyles();

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleClick = () => {
    if (setKey) {
      setKey(values.key);
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.root}
    >
      <Container maxWidth="xs">
        <Paper>
          <Box p={4}>
            <Box
              pb={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img src={Logo} alt="" className={classes.logo} />
            </Box>
            <form
              className={classes.container}
              noValidate
              autoComplete="off"
              onSubmit={handleClick}
            >
              <TextField
                fullWidth
                id="outlined-name"
                label="Ihr Schlüssel"
                value={values.key}
                onChange={handleChange("key")}
                margin="normal"
                variant="outlined"
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                type="submit"
              >
                weiter
              </Button>
            </form>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default KeyEingabe;
